import React, { useContext } from "react";
import { CustomerDetails } from "../../components/Form/CustomerDetails";
import { QuestionSection } from "../../components/utility/quote";
import addToMailchimp from "gatsby-plugin-mailchimp";
import SolarContext from "../../contexts/solarContext";
import { navigate } from "gatsby";

export default function Form({ title }) {
  const { setFormValues, addLeadToBackend } = useContext(SolarContext);
  const subscribeMC = async (values) => {
    await addToMailchimp(values.email, {
      FNAME: values.fullname,
      COMNAME: values.companyname,
      PHONE: values.phonenumber,
      PINCODE: values.postcode,
      PRONAME: title,
    });
  };

  return (
    <QuestionSection
      title={title}
      question="Please provide your few contact details."
      progress="90%"
    >
      <CustomerDetails
        onSubmit={(values, actions) => {
          setFormValues(values);
          const timeOut = setTimeout(() => {
            actions.setSubmitting(false);
            clearTimeout(timeOut);
          }, 1000);
          addLeadToBackend(values);
          subscribeMC(values);
          navigate("/solar/Questions/thankyou");
        }}
      />
    </QuestionSection>
  );
}
