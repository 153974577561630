import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const SolarContext = createContext(initialstate);
SolarContext.displayName = "SolarContext";

export default SolarContext;
